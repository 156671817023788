import './App.css';

import Calculator from './Components/Calculator/Calculator';

function App() {
    return (
        <div>
            <Calculator/>
        </div>
    );
}

export default App;
